import { createApp } from 'vue';
import './assets/styles/app.scss';
import './assets/styles/primevue-theme/tailwind-light/theme.css';
import App from './App.vue';
import '@/utils/veeValidate.js';
import router from './router';
import stores from './stores';
import i18n from '@/i18n';
import componentsRegister from '@/components/componentsRegister.js';
import PrimeVue from 'primevue/config';
import Toast from 'vue-toastification';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';
import VueTippy from 'vue-tippy';
import 'tippy.js/dist/tippy.css';

import ConfirmationService from 'primevue/confirmationservice';

const app = createApp(App);

app.use(Toast, {});
componentsRegister(app);
app.use(i18n);
app.use(router);
app.use(stores);
app.use(VueTippy);
app.use(PrimeVue, {
  locale: {
    accept: 'Si',
    reject: 'No',
    //...
  },
  pt: {
    accordiontab: {
      headerAction: ({ accordiontab }) => {
        return {
          class: [
            'bg-primary text-white rounded-none',
            {
              'rounded-t-md': accordiontab?.context.first,
              'rounded-b-md':
                accordiontab?.context.last && !accordiontab?.context.active,
            },
          ],
        };
      },
      content: ({ accordiontab }) => {
        return {
          class: [
            'border border-primary rounded-none',
            {
              'rounded-b-md': accordiontab?.context.last,
            },
          ],
        };
      },
    },
    panel: {
      toggleableContent: { class: '!border-primary' },
      header: { class: 'bg-primary' },
      title: { class: 'text-white' },
      toggler: { class: 'text-white hover:bg-primary-reverse' },
      root: {
        class: 'border-b border-l border-r border-primary rounded-md',
      },
    },
  },
});
app.use(ConfirmationService);

app.mount('#app');
