import useAuthStore from '@/stores/authStore.js';
import { useRoute } from 'vue-router';
import { computed, ref, unref } from 'vue';
import handleRequest from '@/utils/helpers/handleRequest.js';
import API from '@/utils/api/index.js';
import { console } from 'vuedraggable/src/util/console.js';

const useWithCompanyUrl = (rawCompanyId = 0) => {
  const authStore = useAuthStore();
  const route = useRoute();
  const currentCompanyId = computed(() => {
    return unref(rawCompanyId) || route.params.company || authStore.companyId;
  });

  return (path = '') => {
    return `/companies/${currentCompanyId.value}/${path}`;
  };
};

const useGetCompany = () => {
  const route = useRoute();
  const company = ref(null);
  const getCompany = async companyId => {
    const id = companyId || route.params.company;
    if (!id) {
      console.error('Company id is required');
      return;
    }
    const { isError, data } = await handleRequest(API.get(`/companies/${id}`));
    if (!isError) {
      company.value = data;
    }
  };
  return {
    company,
    getCompany,
  };
};

export { useGetCompany };

export default useWithCompanyUrl;
