<template>
  <div
    class="h-screen bg-primary-blue text-white text-center q-pa-md grid place-content-center"
  >
    <div>
      <div class="text-3xl mb-6 max-w-2xl text-center text-balance">
        <slot>
          {{ $t($route.query.error || $route.meta.error || '') }}
        </slot>
      </div>

      <BaseButton
        v-if="authStore.isAuth"
        class="q-mt-xl"
        color="primary"
        @click="onLogout"
      >
        {{ $t('Logout') }}
      </BaseButton>
    </div>
  </div>
</template>

<script setup>
  import BaseButton from '@/components/UI/BaseButton.vue';
  import useAuthStore from '@/stores/authStore.js';
  const authStore = useAuthStore();
  const { onLogout } = useAuthStore();
</script>
