import useAuthStore from '@/stores/authStore.js';

export default (to, _from, next) => {
  const authStore = useAuthStore();
  if (
    authStore.isAuth &&
    !['dashboard', 'company.form.one'].includes(to.name)
  ) {
    return next({
      name: authStore.isDashboard ? 'dashboard' : 'company.form.one',
    });
  }

  return next();
};
