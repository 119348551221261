<template>
  <BaseButton size="lg" :to="to" :href="href" color="secondary" outlined rounded @click="onClick">
    {{ $t(text) }}
  </BaseButton>
</template>

<script setup>
import { useRouter } from 'vue-router';

const props = defineProps({
  text: {
    type: String,
    default: 'Back',
  },
  to: {
    type: [Object, String],
  },
  href: {
    type: String,
    default: ''
  }
});

const router = useRouter();
const onClick = () => {
  if (props.to || props.href) {
    return;
  }

  router.back();
};
</script>
