import useAuthStore from '@/stores/authStore.js';

export default function reported(to, from, next) {
  const authStore = useAuthStore();
  if (!authStore.isReported && to.name !== 'company.pscl') {
    return next({
      name: 'company.pscl',
    });
  }

  return next();
}
