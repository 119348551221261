import auth from '../middleware/auth';
import dashboard from '../middleware/dashboard';
import checkIsApproveCompany from '@/router/middleware/checkIsApproveCompany.js';
export default [
  {
    path: '/dashboard',

    component: () => import('@/layouts/DashboardLayout.vue'),
    beforeEnter: [auth, dashboard, checkIsApproveCompany],
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import('@/pages/dashboard/PsclHome.vue'),
        meta: {
          name: 'Pscl Home',
          navbar: true,
        },
      },
      {
        path: 'company/:company',
        // name: 'dashboard.company',
        component: () => import('@/pages/dashboard/PSCLCompanyPage.vue'),
        meta: {
          name: 'Pscl Company',
          navbar: false,
        },
      },
      {
        path: 'benchmarking',
        name: 'dashboard.benchmarking',
        component: () => import('@/pages/dashboard/BenchmarkingPage'),
        meta: {
          name: 'Benchmarking',
          navbar: true,
        },
      },
      {
        path: 'measures',
        name: 'dashboard.measures',
        component: () => import('@/pages/dashboard/MeasuresPage.vue'),
        meta: {
          name: 'Measurements',
          navbar: true,
        },
      },
      {
        path: 'map',
        name: 'dashboard.map',
        component: () => import('@/pages/dashboard/MapPage.vue'),
        meta: {
          name: 'Map',
          navbar: true,
        },
      },
      {
        path: 'suggestions',
        name: 'dashboard.suggestions',
        component: () => import('@/pages/dashboard/SuggestionPage.vue'),
        meta: {
          name: 'Suggestions',
          navbar: true,
        },
      },
    ],
  },
];
