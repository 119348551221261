<template>
  <Transition
    enter-active-class="duration-300 ease-out delay-50 transform-gpu"
    enter-from-class=" transform  opacity-0"
    enter-to-class=" opacity-100"
    leave-active-class="duration-200 ease-in delay-50 transform-gpu"
    leave-from-class=" opacity-100"
    leave-to-class="transform   opacity-0 "
    mode="in-out"
    :show="true"
  >
    <slot />
  </Transition>
</template>
