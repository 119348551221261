import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import useAuthStore from '@/stores/authStore.js';
import { t } from '@/i18n';
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, behavior: 'smooth' };
    }
  },
});

let isFetchedUser = false;

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();

  if (!isFetchedUser && !authStore.user) {
    isFetchedUser = true;
    await authStore.getMe();
  }

  return next();
});

const setTitle = text => {
  document.title = text;
  document.querySelector('meta[property="og:title"]').content = text;
};

router.afterEach(to => {
  const title = to?.meta?.title ? t(to?.meta?.title) + ' | KOOLING' : 'KOOLING';
  setTitle(title);
});

export default router;
