// import { configure } from 'vee-validate';
// import { localize } from '@vee-validate/i18n';
// import en from '@vee-validate/i18n/dist/locale/en.json';
// import it from '@vee-validate/i18n/dist/locale/it.json';

import { it } from 'yup-locales';
import { setLocale } from 'yup';
setLocale(it);

// localize({
//   en,
//   it,
// });

// configure({
//   generateMessage: localize({
//     en,
//     it,
//   }),
// });

// setLocale('it');
