import useAuthStore from '@/stores/authStore.js';

export default (to, from, next) => {
  const authStore = useAuthStore();
  if (authStore.isAuth && !authStore.isApproved) {
    return next({
      name: 'company.approved',
    });
  }

  return next();
};
