import API from './http';
import serverErrors from './serverErrors';
import responseMessages from './responseMessages';
import { useToast } from 'vue-toastification';
const toast = useToast();

const fileRequest = req => {
  return req;
};

const getStatus = error => Number(error?.response?.status);

export default {
  get: (url, params = {}, showToast = true) =>
    new Promise(async (resolve, reject) => {
      try {
        const { data } = await API.get('/api' + url, { params: params });
        resolve(data);
      } catch (error) {
        if (showToast && url !== '/me' && getStatus(error) !== 401) {
          toast.error(serverErrors(error));
        }

        return reject(error);
      }
    }),

  post: (url, requestData = {}, message = null, showToast = true) =>
    new Promise(async (resolve, reject) => {
      responseMessages(url);
      try {
        const { data } = await API.post('/api' + url, fileRequest(requestData));
        if (message) {
          toast(message);
        }
        resolve(data);
      } catch (error) {
        if (getStatus(error) !== 401 && showToast) {
          toast.error(serverErrors(error));
        }

        reject(error);
        return error;
      }
    }),

  put: (url, requestData = {}, isFile = false) =>
    new Promise(async (resolve, reject) => {
      try {
        const { data } = isFile
          ? await API.post(
              '/api' + url + '?_method=PUT',
              fileRequest(requestData),
            )
          : await API.put('/api' + url, fileRequest(requestData));
        resolve(data);
      } catch (error) {
        if (getStatus(error) !== 401) {
          toast.error(serverErrors(error));
        }
        reject(error);
      }
    }),

  delete: (url, requestData = {}) =>
    new Promise(async (resolve, reject) => {
      try {
        const { data } = await API.delete('/api' + url, requestData);
        resolve(data);
      } catch (error) {
        if (getStatus(error) !== 401) {
          toast.error(serverErrors(error));
        }
        reject(error);
        return error;
      }
    }),

  getNotApi: (url, params = {}) =>
    new Promise(async (resolve, reject) => {
      try {
        const { data } = await API.get(url, { params: params });
        resolve(data);
      } catch (error) {
        toast.error(serverErrors(error));
        return reject(error);
      }
    }),
  csrf: () =>
    new Promise(async (resolve, reject) => {
      try {
        const { data } = await API.get('/sanctum/csrf-cookie');
        return resolve(data);
      } catch (error) {
        return reject(error);
      }
    }),
};
