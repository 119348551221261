<template>
    <div class="grid grid-cols-1 gap-y-4 lg:gap-y-6 2xl:gap-y-10">
        <slot />
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped></style>