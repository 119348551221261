<template>
  <div class="w-full">
    <label
      v-if="noLabel && !hideLabel"
      :for="id"
      :class="[disabled ? 'text-gray-500' : 'text-primary ']"
      class="h-min-5 mb-1 flex items-center gap-2 pl-4 text-base font-medium text-primary"
    >
      {{ label }}
      <span v-if="required" class="text-danger dark:text-danger">*</span>
    </label>
    <div class="relative group transition">
      <div
        v-if="icon"
        class="absolute left-4 top-0 flex h-full items-center gap-2 text-black"
      >
        <slot name="left-icon" />
        <BaseIcon :name="icon" size="20"></BaseIcon>
      </div>
      <div
        v-if="!noLabel && !hideLabel"
        :class="{
          '!items-start': isHasValue,
          'group-hover:items-start': !disabled,
        }"
        class="absolute inset-0 flex items-center pointer-events-none transition ease-in-out delay-150"
      >
        <label
          v-if="!noLabel"
          :for="id"
          class="mx-2 px-2 font-medium transition rounded-t-2xl bg-gray-100 w-full"
          :class="[
            isActive || inputValue ? 'text-xs pt-1 mt-0.5' : 'text-base',
            disabled
              ? 'text-gray-500'
              : 'text-primary group-hover:text-xs group-hover:mt-0.5',
          ]"
        >
          {{ label }}
          <span v-if="required" class="text-danger dark:text-danger">*</span>
        </label>
      </div>
      <textarea
        :id="id"
        v-model="inputValue"
        :style="`height:${height}`"
        :name="name"
        :aria-label="name"
        :placeholder="placeholder"
        :class="[
          errorMessage
            ? 'border-danger/30 bg-danger/5 text-danger dark:border-danger dark:text-danger'
            : disabled
              ? 'border-blue-100 bg-gray-100 text-black'
              : 'border-gray-100 bg-gray-100 text-black',
          dense ? 'py-2' : 'py-5',
          icon ? 'pl-12' : 'pl-4',
          {
            'placeholder-opacity-0 group-hover:placeholder-opacity-100':
              isHasValue,
          },
        ]"
        class="block w-full rounded-2xl border-2 pr-3 text-base outline-none ring-2 ring-transparent placeholder-gray-500 focus:border-primary focus:ring-primary/30"
        :disabled="disabled"
        @input="onChange"
      />
      <div
        v-if="!alert"
        class="absolute right-4 top-0 flex h-full items-center justify-center gap-2"
      >
        <slot name="right-icon" />
        <BaseIcon
          v-if="disabled && !errorMessage"
          outlined
          name="edit_off"
          size="24"
          class="text-gray-400 dark:text-gray-400 print:hidden"
        ></BaseIcon>
        <slot />
      </div>
    </div>
    <template v-if="alert">
      <BaseAlert :show="!!errorMessage" type="danger">
        {{ errorMessage }}
      </BaseAlert>
    </template>
    <div v-else-if="!noAlert && errorMessage" class="h-5 pl-4">
      <p class="text-xs font-medium text-danger dark:text-danger">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script>
  import { useField } from 'vee-validate';
  import { computed } from 'vue';

  export default {
    components: {
      // VTooltip
    },
    props: {
      label: String,
      name: String,
      id: String,
      placeholder: String,
      modelValue: {
        type: [String, Number],
        default: '',
      },
      icon: {
        type: String,
        default: '',
      },
      errors: {
        type: Object,
        default: () => ({}),
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      dark: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
      dense: {
        type: Boolean,
        default: false,
      },
      maska: {
        type: String,
        default: '',
      },
      tooltip: {
        type: Boolean,
        default: false,
      },
      noLabel: {
        type: Boolean,
        default: false,
      },
      hideLabel: {
        type: Boolean,
        default: false,
      },
      alert: {
        type: Boolean,
        default: false,
      },
      noAlert: {
        type: Boolean,
        default: false,
      },
      hideCheckIcon: {
        type: Boolean,
        default: true,
      },
      height: {
        type: String,
        default: '',
      },
    },
    emits: ['update:modelValue'],

    setup(props, { emit }) {
      const {
        value: inputValue,
        errorMessage,
        handleChange,
        meta,
      } = useField(() => props.name, undefined, {
        initialValue: computed(() => props.modelValue),
        type: 'text',
      });

      const onChange = ({ target }) => {
        handleChange(target.value);
        emit('update:modelValue', target.value);
      };

      const expectLabel = ['time', 'date', 'number'];
      const isHasValue = computed(() => {
        if (expectLabel.includes(props.type)) {
          return true;
        }
        if (props.placeholder) {
          return false;
        }

        return !!inputValue.value;
      });

      const isActive = computed(() => {
        return expectLabel.includes(props.type);
      });

      return {
        errorMessage,
        inputValue,
        onChange,
        meta,
        isHasValue,
        isActive,
      };
    },
  };
</script>

<style></style>
