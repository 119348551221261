<template>
  <CustomTransition>
    <div
      v-if="show"
      class="p-4 mb-4 rounded-lg flex items-center gap-4"
      :class="[
        typeAlert[type],
        { 'alert-dismissible': dismissible },
        center ? 'justify-center' : 'justify-start',
      ]"
      role="alert"
    >
      <BaseIcon v-if="icon" :name="icon" :outlined="outlined" />
      <svg
        v-if="loader"
        class="animate-spin -ml-1 h-7 w-7"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      <span v-if="label" class="font-medium border-r-2 pr-4">{{ label }}</span>
      <slot></slot>

      <button
        v-if="dismissible"
        type="button"
        class="btn-close"
        aria-label="Close"
        @click="$emit('close')"
      ></button>
    </div>
  </CustomTransition>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import CustomTransition from './CustomTransition.vue';

  defineProps({
    type: {
      type: String,
      default: 'success',
      validator: function (value) {
        // The value must match one of these strings
        return (
          [
            'success',
            'warning',
            'danger',
            'info',
            'primary',
            'secondary',
          ].indexOf(value) !== -1
        );
      },
    },
    dismissible: {
      type: Boolean,
      default: true,
    },
    loader: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    label: String,
    icon: String,
  });

  defineEmits(['close']);
  const typeAlert = ref({
    danger: 'text-danger bg-danger/10 dark:bg-danger/10 dark:text-danger',
    primary: 'text-primary bg-info/10 dark:bg-primary/10 dark:text-primary',
    success: 'text-success bg-success/10 dark:bg-success/10 dark:text-white',
    warning: 'text-warning bg-warning/10 dark:bg-warning/10 dark:text-warning',
    info: 'text-info bg-info/5 dark:bg-info/10 dark:text-info',
    secondary: 'text-gray-700 bg-gray-100 dark:bg-gray-700 dark:text-gray-300',
  });
</script>

<style></style>
