import useAuthStore from '@/stores/authStore.js';

export default function auth(to, _from, next) {
  const authStore = useAuthStore();
  if (!authStore.isAuth && to.name !== 'auth.login') {
    return next({
      name: 'auth.login',
    });
  }

  return next();
}
