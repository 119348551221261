import { createI18n } from 'vue-i18n';

import en from './lang/en.json';
import it from './lang/it.json';

const formats = {
  currency: {
    style: 'currency',
    currency: 'EUR',
  },
  decimal: {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
  percent: {
    style: 'percent',
    minimumFractionDigits: 2,
  },
  emission: {
    style: 'decimal',
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  },
  round: {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
};

const numberFormats = {
  en: formats,
  it: formats,
};

const localStorageLang = localStorage.getItem('lang') || 'it';

const i18n = createI18n({
  legacy: false,
  // globalInjection: false,
  locale: localStorageLang, // set locale
  fallbackLocale: 'en',
  formatFallbackMessages: true,
  messages: {
    en: en,
    it: it,
  },
  missingWarn: false,
  fallbackWarn: false,
  globalInjection: true,
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  numberFormats,
});

const { global } = i18n;

const { t, n } = global;

export default i18n;

export { t, n };
