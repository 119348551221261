import useAuthStore from '@/stores/authStore.js';

export default (_to, _from, next) => {
  const authStore = useAuthStore();
  if (!authStore.isCompanySubmitted) {
    return next({
      name: 'company.form.one',
    });
  }

  return next();
};
