import axios from 'axios';
import router from '@/router';
import useAuthStore from '@/stores/authStore.js';
import useGlobalStore from '@/stores/globalStore.js';
import config from '@/globals/config.js';
const ME_URL = '/api/me';

const API = axios.create({
  baseURL: config.API_URL,
  headers: {
    Accept: 'application/json',
  },
  withCredentials: true,
  withXSRFToken: true,
});

export const getCSRF = async () => {
  await API.get(config.API_URL + '/sanctum/csrf-cookie');
};

API.interceptors.request.use(request => {
  const globalStore = useGlobalStore();
  globalStore.toggleLoading(true);
  return request;
});

/**
 * This interceptor will refresh token if it has expired and
 * then it will call all requests that failed with 401 status
 */
API.interceptors.response.use(
  response => {
    const globalStore = useGlobalStore();
    globalStore.toggleLoading(false);
    return Promise.resolve(response);
  },
  async error => {
    const globalStore = useGlobalStore();
    const { config, response } = error;

    if (Number(response?.status) === 419) {
      await getCSRF();
      return axios.request(config);
    }
    if (config?.url !== ME_URL && Number(response?.status) === 401) {
      router.push({ name: 'auth.login' });
      useAuthStore().reset();
    }

    globalStore.toggleLoading(false);
    return Promise.reject(error);
  },
);

export default API;
