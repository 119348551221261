import { t } from '@/i18n';
import { MessagesError } from '@/globals/messages.js';

const initOpts = {
  ignore401: false,
  ignore403: false,
  ignore404: false,
};
export default (error, opts = initOpts) => {
  const { ignore401, ignore403, ignore404 } = { ...initOpts, ...opts };
  const status = error?.response?.status || error?.status;
  if (status === 401 && !ignore401) {
    return t(MessagesError.unauthenticated);
  }
  if (status === 403 && !ignore403) {
    return t(MessagesError.forbidden);
  }

  if (status === 404 && !ignore404) {
    return t(MessagesError.resourceNotFound);
  }
  let message =
    error?.response?.data?.message ||
    error?.response?.message ||
    error?.message ||
    '';

  if (MessagesError[message]) {
    message = t(MessagesError[message]);
  }
  return message || t(MessagesError.somethingWentWrong);
};
