import API from '@/utils/api/index.js';

const useSubmitQuestionnaire = () => {
  return async (form = {}) => {
    await API.getNotApi('/sanctum/csrf-cookie');
    return API.post('/submit-questionnaire', form, null, false);
  };
};

export default useSubmitQuestionnaire;
