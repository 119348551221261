import handleRequest from '@/utils/helpers/handleRequest.js';
import API from '@/utils/api/index.js';
import getServerMessage from '@/utils/api/serverErrors.js';
import { checkValidQuestionnairePersonalLink } from '@/composables/questionnaire-link.js';

export default async to => {
  const { linkData, check } = checkValidQuestionnairePersonalLink();
  if (!check()) {
    const { isError, response, data } = await handleRequest(
      API.get(
        `/questionnaire-personal-links/${to.params.linkId}`,
        undefined,
        false,
      ),
    );
    if (isError) {
      return {
        name: 'error',
        query: {
          error: getServerMessage(response, { ignore403: true }),
        },
      };
    }
    linkData.value = data;
  }
  return {
    name: 'user.questionnaire',
    query: {
      email: linkData.value.email,
      token: linkData.value.token,
    },
  };
};
