import useFilter from 'lodash/filter.js';
import isNil from 'lodash/isNil.js';
import useCloneDeep from 'lodash/cloneDeep.js';
import useMergeWith from 'lodash/mergeWith.js';
import useFind from 'lodash/find.js';
import useSome from 'lodash/some.js';
import useMap from 'lodash/map.js';
import isArray from 'lodash/isArray.js';
import useJoin from 'lodash/join.js';

/**
 * @typedef {Object} UpsertOptions
 * @property {string | string[]} [findKey] - Key for finding the item
 * @property {(data: object) => object} [prepareUpdate] - Function to prepare data for update
 * @property {boolean} [isMerge] - Whether to merge objects
 * @property {boolean} [unshift] - Whether to add the element at the beginning of the array
 */

/**
 * Merges objects but excludes merging arrays
 * @template T
 * @param {T} src - Source object
 * @param {Partial<T>} element - Element to merge
 * @returns {T}
 */
const mergeExcludeArray = (src, element) =>
  useMergeWith(src, element, (_val, newVal) => {
    if (isArray(newVal)) {
      return newVal;
    }
  });

/**
 * Adds or updates an element in an array
 * @template P
 * @template K
 * @param {P[]} arr - Array to update
 * @param {P} element - Element to add or update
 * @param {UpsertOptions<P, K>} [options] - Options for upsert operation
 * @returns {P[]}
 */
const upsert = (arr = [], element, options = {}) => {
  const { findKey = 'id', prepareUpdate, isMerge } = options;
  const array = useCloneDeep(arr);
  const index = array.findIndex(_element => {
    if (isArray(findKey)) {
      return useSome(
        findKey,
        key => !isNil(_element[key]) && _element[key] === element[key],
      );
    }
    return _element[findKey] === element[findKey];
  });
  if (index > -1) {
    if (isMerge) {
      array[index] = mergeExcludeArray(array[index], element);
    } else if (prepareUpdate) {
      array[index] = prepareUpdate(array[index]);
    } else {
      array[index] = element;
    }
  } else {
    options.unshift ? array.unshift(element) : array.push(element);
  }
  return array;
};

/**
 * Wraps a value in an array
 * @template T
 * @param {T} value - Value to wrap
 * @returns {T[]}
 */
const wrapInArray = value =>
  value == null ? [] : isArray(value) ? value : [value];

/**
 * Updates an item in an array
 * @template P
 * @template K
 * @param {P[]} arr - Array to update
 * @param {Partial<P>} newItem - New item data
 * @param {(item: P) => P} [prepareItem] - Function to prepare item
 * @param {K} [findItemKey='id'] - Key to find item
 * @returns {P[]}
 */
const updateItemArray = (arr, newItem, prepareItem, findItemKey = 'id') =>
  useMap(arr, item => {
    if (item?.[findItemKey] === newItem?.[findItemKey]) {
      const updateItem = mergeExcludeArray(item, newItem);
      return prepareItem ? prepareItem(updateItem) : updateItem;
    }
    return item;
  });

/**
 * Updates multiple items in an array
 * @template P
 * @template K
 * @param {P[]} arr - Array to update
 * @param {Partial<P>[]} newItems - New items data
 * @param {(item: P) => P} [prepareItem] - Function to prepare item
 * @param {K} [findItemKey='id'] - Key to find item
 * @returns {P[]}
 */
const updateItemsArray = (arr, newItems, prepareItem, findItemKey = 'id') =>
  useMap(arr, item => {
    if (item[findItemKey]) {
      const newItem = useFind(
        newItems,
        el => el[findItemKey] === item[findItemKey],
      );
      if (newItem) {
        const updateItem = mergeExcludeArray(item, newItem);
        return prepareItem ? prepareItem(updateItem) : updateItem;
      }
    }
    return item;
  });

/**
 * Removes an item from an array by key value
 * @template P
 * @template K
 * @param {P[] | null | undefined} arr - Array to update
 * @param {string | number} excludeVal - Value to exclude
 * @param {K} [key='id'] - Key to find item
 * @returns {P[]}
 */
const removeItemArray = (arr, excludeVal, key = 'id') =>
  useFilter(arr || [], item => item?.[key] !== excludeVal);

/**
 * Joins an array into a string or returns the original string
 * @param {string | string[]} source - Source to join
 * @param {string} [separator] - Separator for join
 * @returns {string}
 */
const arrayJoin = (source, separator) =>
  isArray(source) ? useJoin(source, separator) : source;

export {
  upsert,
  arrayJoin,
  removeItemArray,
  updateItemArray,
  updateItemsArray,
  wrapInArray,
  mergeExcludeArray,
};
