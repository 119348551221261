const urlType = {
  '/job-alert': "subscribe",
  '/candidates': 'jobOfferCandidate',
  '/login': 'login'
}

const text = {
  subscribe: {
    title: "You are already subscribed to our job alert!",
    text: 'Thank you! We sent you an email with a verification link. Click the link to verify your email.',
    errorTitle: 'Subscribe Error',
    errorText: 'We encountered an error. Please try again.'
  },
  jobOfferCandidate: {
    title: 'Your application was successfully sent!',
    text: '',
    errorTitle: 'Error',
    errorText: 'We encountered an error. Please try again.'
  }
}

export default (url) => {
  if (typeof url === 'string') {
    return text[urlType[url]] || { title: '', text: '' }
  }
  return { title: '', text: '' }

}