import isFunction from 'lodash/isFunction.js';

const getResponseData = async rawRes => {
  const res = isFunction(rawRes) ? await rawRes() : await rawRes;
  const data = res?._data || res?.data;
  if (data && Object.keys(data).length !== 1) {
    return data;
  }
  return data?.data || data || res;
};

const handleRequest = async req => {
  try {
    const response = await (isFunction(req) ? req() : req);
    return {
      isError: false,
      response,
      data: await getResponseData(response),
    };
  } catch (e) {
    return {
      isError: true,
      response: e,
      data: await getResponseData(e),
    };
  }
};
export default handleRequest;
