<template>
  <BaseLoader :loading="globalStore.isLoading || authStore.authLoading" />
</template>

<script setup>
  import useAuthStore from '@/stores/authStore.js';
  import BaseLoader from '@/components/UI/BaseLoader.vue';
  import useGlobalStore from '@/stores/globalStore.js';
  const globalStore = useGlobalStore();
  const authStore = useAuthStore();
</script>
