<template>
  <component
    :is="componentId"
    :type="type"
    :class="[buttonSize, buttonColor, buttonStyle, { disabled: disabled }]"
    v-bind="{ ...actionConfig, ...$attrs }"
    class="transition ease-in-out duration-300 cursor-pointer relative disabled:cursor-not-allowed disabled:grayscale disabled:pointer-events-none"
    :disabled="disabled"
  >
    <BaseIcon
      v-if="icon"
      :name="icon"
      :class="[$slots.default ? 'mr-2' : '']"
    />
    <slot />
  </component>
</template>

<script setup>
  import { computed } from 'vue';
  import { RouterLink } from 'vue-router';

  const props = defineProps({
    label: String,
    type: {
      type: String,
      default: 'button',
    },
    id: String,
    placeholder: String,
    modelValue: {
      type: [String, Number],
      default: '',
    },
    href: {
      type: String,
      default: null,
    },
    to: {
      type: Object,
      default: () => ({}),
    },
    icon: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'md',
    },
    color: {
      type: String,
      default: 'primary',
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    tag: String,
  });

  const componentId = computed(() => {
    if (props.tag) {
      return props.tag;
    }
    if (props.to?.name) {
      // return 'fff'
      return RouterLink;
    }
    if (props.href) {
      return 'a';
    }
    return 'button';
  });

  const actionConfig = computed(() => {
    if (props.to?.name) {
      // return 'fff'
      return { to: props.to };
    }
    if (props.href) {
      return { href: props.href };
    }
    return null;
  });

  const buttonSize = computed(() => {
    const defaultClass = ' inline-flex justify-center items-center';
    return (
      {
        '2xl': 'px-10 py-5 text-2xl',
        xl: 'px-10 py-3 text-xl',
        lg: 'px-8 py-3 text-lg',
        md: 'px-6 py-3 text-md',
        mdIcon: 'p-3 text-md',
        sm: 'px-3 py-1 text-sm',
      }[props.size] + defaultClass
    );
  });

  const buttonStyle = computed(() => {
    if (props.rounded) {
      return {
        '2xl': 'rounded-3xl',
        xl: 'rounded-3xl',
        lg: 'rounded-3xl',
        md: 'rounded-2xl',
        mdIcon: 'rounded-2xl',
        sm: 'rounded-lg',
      }[props.size];
    } else {
      return '';
    }
  });

  const buttonColor = computed(() => {
    if (props.disabled) {
      return 'border border-gray-400 bg-gray-400 text-white font-medium';
    }
    if (props.outlined) {
      return {
        primary: 'border border-primary-blue text-primary-blue font-medium',
        secondary:
          'border border-gray-400 text-gray-400 font-medium hover:bg-gray-400 hover:text-white',
        danger: 'border border-danger text-danger font-medium',
        success: 'border border-success text-success font-medium',
        warning: 'border border-warning text-warning font-medium',
      }[props.color];
    }
    return {
      primary:
        'bg-primary-blue text-white font-medium hover:bg-primary focus:bg-primary active:bg-primary',
      secondary:
        'bg-gray-400 text-white font-medium hover:bg-secondary focus:bg-secondary active:bg-secondary',
      danger:
        'bg-danger text-white font-medium hover:bg-danger-hover focus:bg-danger-hover active:bg-danger-hover',
      success:
        'bg-success text-white font-medium hover:bg-success-hover focus:bg-success-hover active:bg-success-hover',
      warning:
        'bg-warning text-white font-medium hover:bg-warning-hover focus:bg-warning-hover active:bg-warning-hover',
    }[props.color];
  });
</script>

<style lang="scss" scoped></style>
