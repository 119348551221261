import getServerMessage from '@/utils/api/serverErrors.js';
import useFacilitiesStore from '@/stores/facilitiesStore.js';

export default async to => {
  const facilitiesStore = useFacilitiesStore();

  const { isError, response } =
    await facilitiesStore.getFacilitySelectWithToken(to.params.companyToken);
  if (isError) {
    return {
      name: 'error',
      query: {
        error: getServerMessage(response, { ignore403: true }),
      },
    };
  }
};
