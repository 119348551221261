import useAuthStore from '@/stores/authStore.js';

export default (_to, _from, next) => {
  const authStore = useAuthStore();
  if (authStore.isApproved) {
    return next({
      name: 'home',
    });
  }
  return next();
};
