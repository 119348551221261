<template>
  <div class="">
    <div class="flex items-center">
      <div class="flex items-center">
        <input
          :id="inputId"
          :type="type"
          :name="name"
          :checked="!!checked"
          :disabled="disabled"
          :value="value"
          :aria-label="name"
          class="accent-primary rounded text-primary transition-all duration-100 ease-out"
          :class="[
            {
              'input-error': errorMessage,
            },

            size ? 'h-7 w-7 md:h-10 md:w-10' : 'h-6 w-6',
          ]"
          @input="handleChange"
          @change="$emit('change', value, $event)"
        />
        <label :for="inputId"></label>
      </div>
      <label
        :for="inputId"
        class="ml-4 text-secondary font-medium flex items-center gap-2 flex-1"
        :class="[
          { 'text-black': errorMessage },
          { 'text-sm font-medium md:text-2xl': size },
        ]"
      >
        <slot name="label">{{ $t(label) }}</slot>
        <span v-if="required" class="text-danger dark:text-danger">*</span>
      </label>
    </div>
    <div v-if="single" class="pl-10">
      <span
        v-show="errorMessage"
        class="text-xs font-medium text-danger dark:text-danger"
      >
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>

<script setup>
  import { useField } from 'vee-validate';
  import uniqueId from 'lodash/uniqueId.js';
  import { computed } from 'vue';

  defineEmits([
    'keydown',
    'blur',
    'change',
    'input',
    'focus',
    'update:modelValue',
  ]);
  const props = defineProps({
    label: String,
    text: String,
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'checkbox',
    },
    size: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    placeholder: String,
    component: {
      type: String,
      default: null,
    },
    cvalue: {
      type: [Boolean, String, Number],
      default: false,
    },
    modelValue: {
      type: [Boolean, String, Number],
      default: false,
    },
    btn: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    single: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    checkedValue: {
      type: [Boolean, String, Number],
      default: '',
    },
    uncheckedValue: {
      type: [Boolean, String, Number],
      default: '',
    },
    enabledControlled: Boolean,
    syncVModel: Boolean,
  });

  // const setSingleError = inject('setSingleError')

  const inputId = computed(() => props.id || uniqueId('checkbox'));

  const { value, errorMessage, handleChange, checked } = useField(
    () => props.name,
    undefined,
    {
      initialValue: props.modelValue,
      type: props.type,
      checkedValue: props.checkedValue,
      uncheckedValue: props.uncheckedValue,
      controlled: !props.enabledControlled,
      syncVModel: props.syncVModel,
    },
  );
</script>

<style scoped>
  .form-checkbox {
    box-shadow: unset !important;
  }
</style>
