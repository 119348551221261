import useAuthStore from '@/stores/authStore.js';

export default (_to, _from, next) => {
  const authStore = useAuthStore();
  if (authStore.isWasPaid) {
    return next({
      name: 'company.form.upload',
    });
  }

  return next();
};
