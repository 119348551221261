import { defineStore } from 'pinia';
import API from '@/utils/api';
import handleRequest from '@/utils/helpers/handleRequest.js';
import useWithCompanyUrl from '@/composables/useWithCompanyUrl.js';
import { computed, ref } from 'vue';
import useFind from 'lodash/find.js';
import useFilter from 'lodash/filter.js';
import findIndex from 'lodash/findIndex.js';
import {
  removeItemArray,
  updateItemArray,
  upsert,
} from '@/utils/helpers/array.js';
import orderBy from 'lodash/orderBy.js';
import sumBy from 'lodash/sumBy.js';
import { replaceEmptyWithNull } from '@/utils/object.js';
import useMap from 'lodash/map.js';

const useFacilitiesStore = defineStore('FacilitiesStore', () => {
  const facilities = ref([]);
  const mapPoints = ref([]);
  const facilitySelect = ref(null);
  const facilitiesAdditional = ref({});
  const withCompanyUrl = useWithCompanyUrl();
  const coreFacility = computed(
    () => useFind(facilities.value, 'is_core') || null,
  );
  const excludeCoreFacilities = computed(() =>
    useFilter(facilities.value, facility => !facility.is_core),
  );
  const staffCount = computed(() => {
    return sumBy(
      facilities.value,
      facility =>
        facility.full_time_staff_count + facility.part_time_staff_count,
    );
  });

  const getFacilityIdx = facilityId =>
    findIndex(facilities.value, { id: facilityId });

  const getFacilities = async () => {
    const { isError, data } = await handleRequest(
      API.get(withCompanyUrl('facilities')),
    );
    if (!isError) {
      facilities.value = orderBy(data, ['is_core'], ['desc']);
    }
  };

  const updateFacility = async (facilityId, updateData) => {
    const { isError, data } = await handleRequest(
      API.put(
        withCompanyUrl('facilities/' + facilityId),
        replaceEmptyWithNull(updateData),
      ),
    );
    if (!isError) {
      facilities.value = updateItemArray(facilities.value, data);
      return data;
    }
    return null;
  };

  const createFacility = async newData => {
    const { isError, data } = await handleRequest(
      API.post(withCompanyUrl('facilities'), replaceEmptyWithNull(newData)),
    );
    if (!isError) {
      setTimeout(() => {
        facilities.value = upsert(facilities.value, data);
      });
      return data;
    }
    return null;
  };

  const deleteFacility = async facilityId => {
    const { isError } = await handleRequest(
      API.delete(withCompanyUrl('facilities/' + facilityId)),
    );
    if (!isError) {
      facilities.value = removeItemArray(facilities.value, facilityId);
      return true;
    }
    return false;
  };

  const getMapPoints = async () => {
    const { isError, data } = await handleRequest(
      API.get(withCompanyUrl('statistic/map-points')),
    );
    if (!isError) {
      mapPoints.value = data;
    }
  };

  const fetchFacilityReports = async facilityId => {
    const facilityIdx = getFacilityIdx(facilityId);
    if (facilityIdx >= 0 && !facilities.value[facilityIdx].reports?.length) {
      const { isError, data } = await handleRequest(
        API.get(withCompanyUrl(`facilities/${facilityId}/reports`)),
      );
      if (!isError) {
        facilities.value[facilityIdx].reports = data;
      }
    }
    return facilities.value[facilityIdx].reports;
  };

  /**
   * Retrieves additional data for a facility, such as working days or working hours.
   *
   * @param {string} type - The type of data to retrieve. Possible values:
   *  - 'working-days' : For retrieving working days.
   *  - 'working-hours' : For retrieving working hours.
   * @param {number} facilityId - The ID of the facility (default is 0).
   * @param {object} payload - Additional parameters for the API request.
   * @returns {Promise<any>} - The retrieved data or cached data.
   */
  const getFacilityAdditionalData = async (
    type,
    facilityId = 0,
    payload = {},
  ) => {
    const { isError, data } = await handleRequest(
      API.get(withCompanyUrl('statistic/' + type), {
        facility_id: facilityId || undefined,
        ...payload,
      }),
    );

    if (!isError) {
      if (!facilitiesAdditional.value[facilityId]) {
        facilitiesAdditional.value[facilityId] = {};
      }
      facilitiesAdditional.value[facilityId][type] = data;
    }
  };

  const getWorkingDays = (facilityId, payload = {}, force = false) =>
    getFacilityAdditionalData('working-days', facilityId, payload, force);

  const getWorkingHours = (facilityId, payload = {}, force = false) =>
    getFacilityAdditionalData('working-hours', facilityId, payload, force);

  const createFreeFlowReport = async (facilityId, newData) => {
    const facilityIdx = getFacilityIdx(facilityId);
    const { isError, data } = await handleRequest(
      API.post(withCompanyUrl(`facilities/${facilityId}/reports`), newData),
    );
    if (isError) {
      return false;
    }
    if (!facilities.value[facilityIdx].reports) {
      setTimeout(() => {
        facilities.value[facilityIdx].reports = [data];
      }, 0);
      return true;
    }
    setTimeout(() => {
      facilities.value[facilityIdx].reports.push(data);
    }, 0);
    return true;
  };

  const updateFreeFlowReport = async (facilityId, reportId, newData) => {
    const facilityIdx = getFacilityIdx(facilityId);
    const { isError, data } = await handleRequest(
      API.put(
        withCompanyUrl(`facilities/${facilityId}/reports/${reportId}`),
        newData,
      ),
    );
    if (isError) {
      return false;
    }
    facilities.value[facilityIdx].reports = updateItemArray(
      facilities.value[facilityIdx].reports,
      data,
    );
    return true;
  };

  const removeFreeFlowReport = async (facilityId, reportId) => {
    const facilityIdx = getFacilityIdx(facilityId);
    const { isError } = await handleRequest(
      API.delete(
        withCompanyUrl(`facilities/${facilityId}/reports/${reportId}`),
      ),
    );
    if (!isError) {
      facilities.value[facilityIdx].reports = removeItemArray(
        facilities.value[facilityIdx].reports,
        reportId,
      );
    }
  };

  const createQuestionnaireLink = async facilityId => {
    const facilityIdx = getFacilityIdx(facilityId);
    const { isError, data } = await handleRequest(
      API.post(withCompanyUrl(`facilities/${facilityId}/questionnaire-link`)),
    );

    if (!isError) {
      facilities.value[facilityIdx].questionnaire_link = data;
    }
  };

  const updateQuestionnaireLink = async (facilityId, reqData) => {
    const facilityIdx = getFacilityIdx(facilityId);
    const { isError, data } = await handleRequest(
      API.put(
        withCompanyUrl(`facilities/${facilityId}/questionnaire-link`),
        reqData,
      ),
    );

    if (!isError) {
      facilities.value[facilityIdx].questionnaire_link = data;
    }
  };

  const deleteQuestionnaireLink = async facilityId => {
    const facilityIdx = getFacilityIdx(facilityId);
    const { isError } = await handleRequest(
      API.delete(withCompanyUrl(`facilities/${facilityId}/questionnaire-link`)),
    );
    if (!isError) {
      facilities.value[facilityIdx].questionnaire_link = null;
    }
  };

  const getFacilitySelectWithToken = async token => {
    const res = await handleRequest(
      API.get(`/company-questionnaire-facilities/${token}`, undefined, false),
    );
    if (!res.isError) {
      facilitySelect.value = useMap(res.data, (name, value) => ({
        name,
        value,
      }));
    }
    return res;
  };

  const reset = () => {
    facilities.value = [];
    mapPoints.value = [];
    facilitiesAdditional.value = {};
    facilitySelect.value = null;
  };

  return {
    facilities,
    coreFacility,
    excludeCoreFacilities,
    staffCount,
    mapPoints,
    facilitiesAdditional,
    facilitySelect,
    getWorkingDays,
    getWorkingHours,
    getFacilitySelectWithToken,

    getFacilities,
    fetchFacilityReports,
    updateFacility,
    createFacility,
    deleteFacility,
    getMapPoints,
    createFreeFlowReport,
    updateFreeFlowReport,
    removeFreeFlowReport,
    reset,
    createQuestionnaireLink,
    updateQuestionnaireLink,
    deleteQuestionnaireLink,
  };
});

export default useFacilitiesStore;
