import LineTitle from './layout/LineTitle.vue';

//UI
import BaseButton from './UI/BaseButton.vue';
import BaseIcon from './UI/BaseIcon.vue';
import BaseTitle from './layout/BaseTitle.vue';
import BaseAlert from './UI/BaseAlert.vue';
import BaseBackBtn from '@/components/UI/BaseBackBtn.vue';
import PageWrapper from '@/components/layout/PageWrapper.vue';

//form
import BaseCheckbox from './form/BaseCheckbox.vue';
import BaseInput from './form/BaseInput.vue';
import MultiSelect from './form/MultiSelect.vue';
import BaseTextArea from './form/BaseTextArea.vue';
import BaseRadio from './form/BaseRadio.vue';

export default (app) => {
  app.component('LineTitle', LineTitle);
  app.component('BaseInput', BaseInput);
  app.component('BaseCheckbox', BaseCheckbox);
  app.component('BaseButton', BaseButton);
  app.component('BaseIcon', BaseIcon);
  app.component('MultiSelect', MultiSelect);
  app.component('BaseTitle', BaseTitle);
  app.component('BaseAlert', BaseAlert);
  app.component('BaseTextArea', BaseTextArea);
  app.component('BaseRadio', BaseRadio);
  app.component('BaseBackBtn', BaseBackBtn);
  app.component('PageWrapper', PageWrapper);
};
