import { useLocalStorage, StorageSerializers } from '@vueuse/core';
import dayjs from 'dayjs';

const STORAGE_KEY = 'qPersonalLink';

const useGetQuestionnairePersonalLink = () => {
  return useLocalStorage(STORAGE_KEY, null, {
    serializer: StorageSerializers.object,
  });
};

const checkValidQuestionnairePersonalLink = () => {
  const linkData = useGetQuestionnairePersonalLink();
  const check = () => {
    if (!linkData.value) {
      return false;
    }
    return dayjs().diff(dayjs.unix(linkData.value.timestamp), 'd') < 5;
  };
  return {
    linkData,
    check,
  };
};

export { useGetQuestionnairePersonalLink, checkValidQuestionnairePersonalLink };
