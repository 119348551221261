import mapValues from 'lodash/mapValues.js';
import isObject from 'lodash/isObject.js';
import isArray from 'lodash/isArray.js';
import isEmpty from 'lodash/isEmpty.js';

const isNullOrEmptyUndefined = value => {
  return value === '' || value === undefined || value === null;
};

const deepEqualWithEmptyCheck = (newVal, initVal) => {
  if (
    typeof newVal !== 'object' ||
    typeof initVal !== 'object' ||
    newVal === null ||
    initVal === null
  ) {
    return (
      (isNullOrEmptyUndefined(newVal) && isNullOrEmptyUndefined(initVal)) ||
      newVal === initVal
    );
  }

  return Object.keys(newVal).every(key =>
    deepEqualWithEmptyCheck(newVal[key], initVal[key]),
  );
};

const replaceEmptyWithNull = obj => {
  return mapValues(obj, value => {
    if (isEmpty(value) && (isObject(value) || isArray(value))) {
      return null;
    }
    return value;
  });
};

export { deepEqualWithEmptyCheck, replaceEmptyWithNull };
